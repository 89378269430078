














import Vue from 'vue';
import axios from 'axios';
import Header from '@/components/Header/Header.vue';
import Footer from '@/components/Footer.vue';
import Error from '@/components/Error.vue';
import Loading from '@/components/Loading.vue';
import Legend from '@/components/Legend.vue';
import { ScheduleEvent } from '@/schedule/schedule2';

export default Vue.extend({
  components: { Loading, Error, Header, Footer, Legend },
  data() {
    return {
      schedule: null as ScheduleEvent[] | null,
      day: null as string | null,
      loading: true
    };
  },
  async mounted() {
    try {
      const response = await axios.get<ScheduleEvent[]>('https://unlocked-planning.s3.eu-west-3.amazonaws.com/unlocked.json');
      if (response.status === 200) {
        this.schedule = response.data;
        this.day = response.data[0].fromTime;
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }
});
